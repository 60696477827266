import styled from "styled-components"

export const GridIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 0.2rem;
  row-gap: 0.5rem;
`

export const Technology = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 0.3rem;
  font-size: 0.7rem;
  color: white;
  background: ${({ theme }) => theme.action};
  box-shadow: 0 20px 40px ${({ theme }) => theme.shadow};
  border-radius: 5px;
  display: grid;
  place-items: center;
  padding: 0.3rem;
`
