import React from "react"
import { useLocalStorage } from "react-use"
import { ThemeProvider } from "styled-components"
import GlobalStyles from "../components/GlobalStyles"
import { LocalStorageContext } from "../localStorageContext"
import { darkTheme, lightTheme } from "../theme"

export const WrapPage = ({ children }) => {
  const [value, setValue] = useLocalStorage("theme", "dark")

  //setup theme
  const themeMode = value === "dark" ? darkTheme : lightTheme

  return (
    <ThemeProvider theme={themeMode}>
      <LocalStorageContext.Provider value={{ value, setValue }}>
        <GlobalStyles />
        {children}
      </LocalStorageContext.Provider>
    </ThemeProvider>
  )
}
