import { Link } from "gatsby"
import styled from "styled-components"

export const ButtonPrimary = styled(Link)`
  width: ${props => (props.large ? "290px" : "190px")};
  height: ${props => (props.large ? "50px" : "40px")};
  border-radius: 10px;
  background: ${({ theme }) => theme.action};
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0 1.3rem;
  text-decoration: none;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: grid;
  place-items: center;
  transition: 0.4s cubic-bezier(0.2, 0.82, 0.2, 1);
  &:hover {
    background: ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.action};
    transform: translateY(-5px);
  }
`
export const ButtonTagA = styled.a`
  width: 150px;
  height: 40px;
  border-radius: 10px;
  background: ${props => (props.blue ? "#4e89ae" : "#ED6663")};
  color: white;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: grid;
  place-items: center;
  margin-right: 0.5rem;
  transition: 0.4s cubic-bezier(0.2, 0.82, 0.2, 1);
  &:hover {
    background: black;
    color: white;
    transform: translateY(-5px);
  }
  @media (max-width: 800px) {
    width: 100px;
  }
`

export const ButtonOutline = styled.a`
  width: 80px;
  height: 30px;
  text-align: center;
  display: grid;
  place-items: center;
  color: white;
  text-decoration: none;
  border: 1px solid white;
  border-radius: 10px;
  font-size: 0.8rem;
  margin-right: 0.8rem;
  &:hover {
    background: white;
    color: #ed6663;
    font-weight: bold;
  }
`

export const GroupButtons = styled.div`
  display: flex;
  padding: 1rem 0;
`
