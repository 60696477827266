import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle` 
::selection {
  background-color: ${({ theme }) => theme.action};
  color: white;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  
  box-sizing: border-box;
}
body{
    background:${({ theme }) => theme.body};
    color:${({ theme }) => theme.text}; 
    font-family: "DM Sans", sans-serif;
    margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  
  }
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

h2{
  color:${({ theme }) => theme.text}; 
}
  img {
  border-style: none;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
svg:not(:root) {
  overflow: hidden;
}
* {
  box-sizing: inherit;
}
*:before {
  box-sizing: inherit;
}
*:after {
  box-sizing: inherit;
}
p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  font-size: 1.1rem;
}
b {
  font-weight: bold;
}
strong {
  font-weight: bold;
}
a{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  color:${({ theme }) => theme.text}; 
}
path.themeText{
  fill:${({ theme }) => theme.text}; 
}
@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
}
`
export default GlobalStyles
