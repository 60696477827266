import React from "react"
import {
  ButtonOutline,
  ButtonPrimary,
  ButtonTagA,
  GroupButtons,
} from "./styled"

export const Button = ({ text, url, large }) => (
  <ButtonPrimary to={url} large={large}>
    <span>{text}</span>
  </ButtonPrimary>
)

export const ButtonA = ({ text, url, isBlue }) => (
  <ButtonTagA href={url} target="_blank" rel="noopener" blue={isBlue}>
    {text}
  </ButtonTagA>
)

export const ButtonMinimal = ({ text, url }) => (
  <ButtonOutline href={url} target="_blank" rel="noopener">
    {text}
  </ButtonOutline>
)
export const ButtonsProjects = ({ demo, repo, isBlue }) => (
  <GroupButtons>
    <ButtonTagA href={demo} target="_blank" rel="noopener" blue={isBlue}>
      Demo
    </ButtonTagA>
    <ButtonTagA href={repo} target="_blank" rel="noopener">
      GitHub
    </ButtonTagA>
  </GroupButtons>
)
