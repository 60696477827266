import "@fontsource/dm-sans/500.css"
import "@fontsource/dm-sans/700.css"
import { loadableReady } from "@loadable/component"
import React from "react"
import ReactDOM from "react-dom"
import { WrapPage } from "./src/components/wrapPage"

export const wrapRootElement = ({ element }) => <WrapPage>{element}</WrapPage>

export const onClientEntry = () => {
  console.log(`Hello World`)
  console.log("%cIt works on my machine.", "color: cyan; font-style: italic")
  console.log(
    "%cWhen all else fails … reboot..",
    "color: teal; font-style: italic"
  )
}

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback)
    })
  }
}
