import React from "react"
import { GridIcons, Technology } from "./styled"

const Stack = ({ logos }) => {
  return (
    <GridIcons>
      {logos.map(name => (
        <Technology key={`${name}-${Date.now()}`}>{name}</Technology>
      ))}
    </GridIcons>
  )
}

export default Stack
