export const darkTheme = {
  body: "#373741",
  text: "#fff",
  action: "#4E89AE",
  background: "#373741",
  shadow: "hsl(0deg 0% 14% / 0.34)",
  activeLink: "#373741",
}

export const lightTheme = {
  body: "#fff",
  text: "#373741",
  action: "#ED6663",
  background: "#fff",
  shadow: "hsl(0deg 0% 50% / 0.34)",
  activeLink: "#fff",
}
